import authProvider from 'providers/AuthProvider';

class OrdersExportNormalizer {
  static requiredExportFields = [
    'date_from',
    'date_to',
    'format',
    'email',
  ];

  static optionalFields = [
    'country',
    'lifetime',

  ];

  static async normalize(params) {
    const normalized = {};
    const { email } = await authProvider.getEmail();
    // eslint-disable-next-line no-restricted-syntax
    for (const key of OrdersExportNormalizer.requiredExportFields) {
      if (!(key in params) && key !== 'email') {
        throw new Error(`${key.replace('_', ' ')} is required.`);
      }

      normalized[key] = key === 'email' ? email : params[key];
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const key of OrdersExportNormalizer.optionalFields) {
      if (key in params) {
        normalized[key] = params[key];
      }
    }

    return normalized;
  }
}

export default OrdersExportNormalizer;

import React from 'react';
import {
  Show,
  SimpleShowLayout,
  DateField,
  TextField,
  BooleanField,
  useShowController,
} from 'react-admin';
import { UserActions } from './UserActions';

export const UserShow = (props) => {
  const { loading } = useShowController(props);

  return (
    <Show actions={<UserActions loading={loading} />} {...props}>
      <SimpleShowLayout>
        <TextField label="Id" source="id" />
        <TextField label="App Id" source="core_id" />
        <TextField label="Email" source="email" />
        <TextField label="Name" source="name" />
        <TextField label="Surname" source="surname" />
        <DateField label="Create date" source="created_at" />
        <DateField label="Update date" source="updated_at" />
        <BooleanField label="Is paid" source="is_paid" />
        <TextField label="Country" source="country" />
        <TextField label="Payment platform" source="payment_platform" />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
